// every dependency has to be imported here
// css, scss, image, fonts etc.
import bulma from "./assets/scss/bulma.scss"; // main bulma styles
import {
  Bulma
} from './classes/Bulma'; // bulma stuff. Navi etc.
import 'lazysizes'; // https://www.npmjs.com/package/lazysizes
import GLightbox from 'glightbox'; // https://www.npmjs.com/package/glightbox
import "../node_modules/glightbox/dist/css/glightbox.min.css";
import Splide from '@splidejs/splide'; // slider/carousel https://splidejs.com/
import "@splidejs/splide/dist/css/splide.min.css";
import "@mdi/font/css/materialdesignicons.min.css";
import youtubeDSGVO from './classes/youtubeDSGVO';
import "../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
import "../node_modules/@glidejs/glide/dist/css/glide.theme.min.css";
import Glide from '@glidejs/glide'
import {
  jarallax
} from 'jarallax';

/* init methods */
new Bulma().initNavbar();
//new Bulma().initDropdowns();
const lightbox = GLightbox({});

const splideEl = document.querySelector('.splide');
if (splideEl) {

  var splide = new Splide( '.splide', {
    type   : 'loop',
    perPage: 3,
    breakpoints: {
      640: {
        perPage: 2,
      },
    }
  } );
  splide.mount();
}

jarallax(document.querySelectorAll('.jarallax'), {
  speed: 0.2
});


let glideSlides = document.getElementsByClassName('glide-slide');
Object.keys(glideSlides).forEach(function (i) {

  glideSlides[i].setAttribute("id", "glideobj_" + i);

  let perView = parseInt(glideSlides[i].getAttribute("data-perview"))
  let type = glideSlides[i].getAttribute("data-type")
  let autoplay = parseInt(glideSlides[i].getAttribute("data-autoplay"))

  let breakpoints = {};
  if (perView > 1) {

    breakpoints = {
      1024: {
        perView: 2
      },
      600: {
        perView: 1
      }
    };

  }

  // bullets
  let bullets = '';
  let glide__bullets = glideSlides[i].getElementsByClassName('glide__bullets')[0];
  let slideItems = glideSlides[i].getElementsByClassName('glide__slide');
  Object.keys(slideItems).forEach(function (j) {
    bullets += `<button class="glide__bullet" data-glide-dir="=${j}"></button>`;
  })

  glide__bullets.innerHTML = bullets;


  var thisGlide = new Glide('#glideobj_' + i, {
    type: type,
    focusAt: 'center',
    perView: perView,
    autoplay: autoplay,
    hoverpause: true,
    breakpoints: breakpoints,
  })

  thisGlide.mount()

});



if (document.querySelectorAll('.g360_ytembed') && document.querySelectorAll('.g360_ytembed').length > 0) {
  localStorage.setItem('g360_ytlisten', false); // listener not listening
  new youtubeDSGVO().init('');
}